import { Box, Button, Container, Divider, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { t } from 'i18next';

import { LoginProps } from '../../interfaces/ILogin';

import LoginForm from '../../components/login/login-form';
import Logo from '../../components/login/logo';
import SocialAuth from '../../components/login/social';

import { useInstance } from '../../hooks/useInstance';
import { cleanMeetingFromLocalStorage } from '../../helpers/localStorage';
import { ContentStyle } from '../../style/content-style';
import { HeadingStyle } from '../../style/heading-style';
import { config } from '../../config/configurations';
import { useAuth } from './login-context';
import { forceLogout } from '../../apis/authentication';
import { fadeInUp } from './auth-styles';

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [showInformation, setShowInformation] = React.useState(false);
  const { instance, setInstance } = useInstance();
  const { isLoggedIn, logout } = useAuth();

  useEffect(() => {
    if (localStorage.getItem('integrationMode') === 'teams') {
      window.location.href = '/config';
    }
    sessionStorage.clear();
    cleanMeetingFromLocalStorage();
    init();
  }, []);

  const init = async () => {
    try {
      const baseURL: string =
        process.env.REACT_APP_URL_BACKEND_API || config.REACT_APP_URL_BACKEND_API;
      const instanceData = await fetchInstanceData(baseURL);
      if (instanceData) {
        setInstance(instanceData);
      } else {
        // eslint-disable-next-line no-console
        console.error('Initialization failed: Unable to fetch instance data.');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during initialization:', error);
    }
  };

  const fetchInstanceData = async (baseURL: string) => {
    try {
      const response = await axios.get(`${baseURL}/init`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      const env = process.env.REACT_APP_ENVIRONMENT;
      if (env?.toLocaleLowerCase() === 'development') {
        const resp = await axios.get('https://api.staging.sbrain.io/init');
        if (resp.status === 200) {
          return resp.data;
        }
      }
      return null;
    }
  };

  useEffect(() => {
    if (instance === '') {
      return;
    }

    if (isLoggedIn) {
      window.location.href = '/';
    } else if (window.location.pathname === '/login') {
      return;
    } else {
      forceLogout();
      logout();
    }
  }, [instance]);

  return (
    <Box
      sx={{
        background: 'background.default',
        minHeight: '100vh',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Container>
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo />
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              {t('GLOBAL_AUTH_LOGIN_TO_ACCOUNT')}
            </Typography>
          </HeadingStyle>
          <Box
            component={motion.div}
            {...fadeInUp}
            sx={{
              backgroundColor: 'background.default',
            }}
          >
            <SocialAuth direction="row" {...props} />
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('GLOBAL_AUTH_OR')}
            </Typography>
          </Divider>
          <Box component={motion.div} {...fadeInUp}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => setShowInformation(!showInformation)}
              startIcon={
                <img
                  src="/assets/images/secondbrain_grey.svg"
                  alt="secondbrain"
                  width="20"
                  height="20"
                />
              }
              sx={{
                border: '2px solid #ccc',
                borderRadius: '5px',
                padding: '0.5675rem',
                flex: 1,
                color: 'text.secondary',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  border: '2px solid #ccc',
                  padding: '0.5675rem',
                  flex: 1,
                },
              }}
            >
              {t('GLOBAL_AUTH_USE_SBACCOUNT')}
            </Button>
          </Box>
          {showInformation && (
            <Box component={motion.div} {...fadeInUp} sx={{ mt: 4 }}>
              <LoginForm />
            </Box>
          )}
          <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 4 }}
          >
            {`${t('GLOBAL_AUTH_DONT_HAVE_ACCOUNT')} `}
            <Link variant="subtitle2" component={RouterLink} to="/signup">
              {t('GLOBAL_AUTH_SIGNUP')}
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </Box>
  );
};

export default Login;
